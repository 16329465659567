import React, { useState } from 'react'
import SEO from "./seo"
import { gql } from "apollo-boost"
import { useQuery } from 'react-apollo'
import MaterialTable from "material-table"
import { IconButton } from '@material-ui/core/'
import Icon from '@material-ui/core/Icon'
import Profile from './profile'
import { getUser } from '../services/auth'

const GET_USERS = gql`
    query users { 
        users {
            users {
                id
                firstName
                lastName
                email
                password
                passwordDate
                kareoId
                roles {
                    id
                    name
                }
            }
        }
    }
`

const Users = () => {
    const user = getUser();

    const users = useQuery(GET_USERS)
    const [selectedUser, setSelectedUser] = useState(null)
    const columns = [{ title: "First Name", field: "firstName" }, { title: "Last Name", field: "lastName" }, { title: "Email", field: "email" }, { title: "Kareo ID", field: "kareoId" }]

    const components = {
        Action: props => {
            if (props.action.icon === 'edit') {
                return (
                    <IconButton aria-label={props.action.icon} size="small"
                        onClick={() => { setSelectedUser(props.data) }}
                    >
                        <Icon>{props.action.icon}</Icon>
                    </IconButton>
                )
            }
        }
    }

    if (getUser().role !== "1") {
        return (
            <div className="mb-20">
                <SEO title="Auth" />
                <h1>Permission Denied</h1>
            </div>
        )
    }
    else if (selectedUser) {
        return (
            <Profile refetch={users.refetch} setSelectedUser={setSelectedUser} selectedUser={selectedUser} />
        )
    }
    else if (users.data && !users.loading) {
        return (
            <div className="mb-20">
                <SEO title="Users" />
                <h1>Manage Users</h1>
                <button onClick={() => { setSelectedUser("New User") }} id="newUser" className="mb-1 inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
                    New User
                </button>
                <div>
                    <MaterialTable style={{ padding: "10px" }}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} of {count}'
                            },
                            toolbar: {
                                nRowsSelected: '{0} row(s) selected'
                            },
                            header: {
                                actions: 'Actions'
                            },
                            body: {
                                emptyDataSourceMessage: 'No records to display',
                                filterRow: {
                                    filterTooltip: 'Filter'
                                }
                            }
                        }}
                        columns={columns}
                        data={users.data.users.users}
                        components={components}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit'
                            }
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            pageSizeOptions: [10],
                            search: false
                        }}
                        title=""
                    />
                </div>
            </div>
        )
    }
    return (
        <div className="mb-20">
            <SEO title="Users" />
            <h1>Manage Users</h1>
            <h2>Loading...</h2>
        </div>
    )
}

export default Users