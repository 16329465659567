
import React, { useState, useEffect } from "react"
import CreatableSelect from 'react-select/creatable'
import SEO from "./seo"
import { gql } from "apollo-boost"
import { useMutation, useQuery, useLazyQuery } from 'react-apollo'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { getUser } from "../services/auth"
import { pxBundles } from '../env/connection'
import { validateChargeTicket } from './charge-sheet/charge-ticket-validator';
import { RFA_EQUIPMENT_KEY } from '../constants'
import stringSimilarity from 'string-similarity';
import dayjs from 'dayjs';
import { useLocation } from '@reach/router';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpIcon from '@material-ui/icons/Help';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import Chip from '@material-ui/core/Chip';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import ChargeSheetDataProvider, { KareoStatus } from './charge-sheet/charge-sheet-data-provider';
import CptSearch from './charge-sheet/cpt-search.js';

import { GET_CPT_TIERS, GET_KAREO_LOCATIONS, GET_REFERRING_PROVIDERS } from './queries/queries';
import { CREATE_REFERRING_PROVIDER } from './mutations/mutations';
import { ChargeClient } from "../providers/charge-client";
import { checkForNotifications } from './charge-sheet/charge-ticket-notifications';

import { ChargeEvents, EventBus } from "../providers/event-bus";
import PermissionsProvider, { HPM_ROLE } from "../providers/permissions-provider";
import { getDateKey } from "../utils/dateUtils"
import ClinicProceduresCptSearch from "./charge-sheet/clinic-proc-cpt-search.js"
import { TierUtils } from "../utils/tierUtils.js"
import IcdSelector from "./charge-sheet/icd-code-selector.js"

// const key = process.env.KAREO_KEY
// const password = process.env.KAREO_PASSWORD
// const username = process.env.KAREO_USERNAME
const key = "KAREO_KEY";
const password = "KAREO_PASSWORD";
const username = "KAREO_USERNAME";

let envName = 'PROD';
if (typeof window !== "undefined") {
	if (window.location.href.indexOf('test.hpmcharge.com') > -1) {
		envName = 'TEST';
	}
	if (window.location.href.indexOf('dev.hpmcharge.com') > -1) {
		envName = 'DEV';
	}
	if (window.location.href.indexOf('localhost') > -1) {
		envName = 'LOCAL';
	}
}

const CustomInput = React.forwardRef((props, ref) => (
	<input aria-label="date" className="m-1 shadow appearance-none border rounded max-w-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={props.value} onChange={(e) => e.preventDefault()} onClick={props.onClick} />
))

const imagingCpts = [
	'64620',
	'64624',
	'64625',
	'64632',
	'64633',
	'64634',
	'64635',
	'64636',
	'64640'
];

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 250,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	patientCounterContainer: {
		fontStyle: 'italic',
		marginBottom: '16px'
	},
	patientCounter: {
	}
}));

// const GET_PROCEDURES = gql`
//     query cpts {
//       cpts(orderBy: { code: desc }) {
// 		cpts {
// 			id
// 			class
// 			description
// 			code
// 			primaryUnits
// 			secondaryUnits
// 			type
// 		}
//       }
//     }
// `;

const GET_PROCEDURES = gql`
    query cpts ($filter: String!) {
      cpts(filter: $filter, orderBy: { code: desc }) {
		cpts {
			id
			class
			description
			code
			primaryUnits
			secondaryUnits
			type
			cpttiers {
				id
				tierId
				tierName
			}
		}
      }
    }
`;

// const GET_APPOINTMENTS = gql`
//   query appointments ($xml: String!, $url: String!, $headers: String!) {
//     appointments (xml: $xml, url: $url, headers: $headers) {
//       appointments
//     }
//   }
// `

const CREATE_TICKET = gql`
	mutation createChargeTicket($chargeTicket: ChargeTicketInput!) {
		createChargeTicket(chargeTicket: $chargeTicket) {
      		id
		}
	}
`

const UPDATE_TICKET = gql`
	mutation updateChargeTicket($chargeTicket: ChargeTicketUpdateInput!) {
		updateChargeTicket(chargeTicket: $chargeTicket) {
      		id
		}
	}
`

const DELETE_TICKET = gql`
	mutation deleteChargeTicket($id: ID!) {
		deleteChargeTicket(id: $id) {
      id
		}
	}
`

const GET_USERS = gql`
    query users {
        users {
            users {
                id
                firstName
                lastName
                email
                kareoId
				isActive
                roles {
                    id
					name
					# rolePermissions {
					# 	roleId
					# 	permissionId
					# 	permission {
					# 		id
					# 		code
					# 		description
					# 	}
					# }
                }
            }
        }
    }
`

const GET_CHARGE_TICKETS = gql`
    query chargeTickets ($filter: String!, $skip: Int!, $take: Int!) {
        chargeTickets (orderBy: {id: desc}, filter: $filter, skip: $skip, take: $take) {
            count
            chargeTickets {
                id
				kareoApptId
                user {
                    id
                    firstName
                    lastName
                    roles {
                        id
                    }
                }
                date
                location
                createdDate
                modifiedDate
                serviceLocationName
                patientName
                mrn
                insurance
                procedures
                trainer
                studentType
                imagingEquipment
				referredTo
                chargeTicketCpts {
                    cpt {
                        id
                        description
                        code
                        primaryUnits
                        secondaryUnits
                        class
                        type
                    }
                    sequence
					cptType
                }
                ticketStatus {
                    downloadDate
                    user {
                        id
                    }
                }
            }
        }
    }
`

const url = 'https://webservice.kareo.com/services/soap/2.1/KareoServices.svc'
const apptsHeaders = {
	'Content-Type': 'text/xml;charset=UTF-8',
	SOAPAction: 'http://www.kareo.com/api/schemas/KareoServices/GetAppointments',
}

const dateFormatter = new Intl.DateTimeFormat('en-us', {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	timeZone: 'UTC'
})

const insuranceOptions = ["Medicare", "Medicare Advantage", "Medicaid", "Cigna", "BCBS", "Humana", "Aetna", "UHC", "Worker's Comp", "VA", "Other", "N/A"];


const dataProvider = ChargeSheetDataProvider();
const chargeClient = new ChargeClient();

const ChargeSheet = (props) => {
	const classes = useStyles();
	const user = getUser();

	const [isLoading, setIsLoading] = useState(true);

	const accessProps = useLocation();
	const createNotificationTicket = accessProps.state ? accessProps.state.hasOwnProperty('id') : false;

	let initialData = null;
	if (props.selectedTicket) {
		initialData = props.selectedTicket;
		initialData.dataType = 'EDIT';
	}
	if (accessProps.state && accessProps.state.hasOwnProperty('id')) {
		initialData = accessProps.state;
		initialData.dataType = 'CREATE';
	}
	// ? props.selectedTicket : (accessProps.state && accessProps.state.hasOwnProperty('id') ? accessProps.state : null);
	// console.log(`initialData = `, initialData);

	let today = new Date();

	// if (props.selectedTicket) {
	// 	today = new Date(dateFormatter.format(new Date(props.selectedTicket.date)));
	// } else if (createNotificationTicket) {
	// 	today = new Date(dateFormatter.format(new Date(accessProps.state.date)));
	// }

	// const title = props.selectedTicket ? "Edit Ticket" : (user.role === "4" ? "Training Log" : "Charge Capture");
	// const title = initialData && initialData.dataType == 'EDIT' ? "Edit Ticket" : (user.role === "4" ? "Training Log" : "Charge Capture");
	const title = initialData && initialData.dataType == 'EDIT'
		? 'Edit Ticket'
		: ((PermissionsProvider.hasRole(user, 'Training') && !PermissionsProvider.hasRole(user, 'Clinic')) // || (PermissionsProvider.hasRoles(user, ['Clinic', 'Training']) && isTrainingChecked)
			? 'Training Log'
			: 'Charge Capture');

	const [spinning, setSpinning] = useState(false)
	// const [date, setDate] = useState('6/27/2023'); // for testing initial load on any given date
	const [date, setDate] = useState(props.selectedTicket
		? `${new Date(props.selectedTicket.date).getMonth() + 1}/${new Date(props.selectedTicket.date).getDate()}/${new Date(props.selectedTicket.date).getFullYear()}`
		: `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`
	);
	const [location, setLocation] = useState({ label: '', key: '' }); //props.selectedTicket ? mapToKareoLocationByName(props.selectedTicket.location) : null)
	const [serviceLocation, setServiceLocation] = useState(props.selectedTicket?.serviceLocationName || accessProps.state?.serviceLocationName || null)
	const [mrn, setMrn] = useState(props.selectedTicket?.mrn || accessProps.state?.mrn || null);
	const [description, setDescription] = useState(props.selectedTicket ? props.selectedTicket.procedures : null)
	const [patientName, setPatientName] = useState(props.selectedTicket?.patientName || accessProps.state?.patientName || null);
	const [insurance, setInsurance] = useState([])
	const [patientsToday, setPatientsToday] = useState([]);
	const [patients, setPatients] = useState([]);
	const [tickets, setTickets] = useState([]);
	const [otherInsurance, setOtherInsurance] = useState(false)
	const [otherInsuranceValue, setOtherInsuranceValue] = useState(null)
	const [blindInj, setBlindInj] = useState(false)
	const [ptOrdered, setPtOrdered] = useState(false)
	const [labOrdered, setLabOrdered] = useState(false)
	const [pxOrdered, setPxOrdered] = useState(false)
	const [imagingOrdered, setImagingOrdered] = useState(false)
	const [count, setCount] = useState(0)
	const [completedProcedures, setCompletedProcedures] = useState([]);
	const [kareoApptId, setKareoApptId] = useState(props.selectedTicket ? props.selectedTicket.kareoApptId : null);

	const [patientNotifications, setPatientNotifications] = useState([]);
	const [warningNotifications, setWarningNotifications] = useState([]);

	// referrals
	const referralOptions = [
		{ value: 'Orthopedic', label: 'Orthopedic' },
		{ value: 'Neurosurgery', label: 'Neurosurgery' },
		{ value: 'Outside Pain Management', label: 'Outside Pain Management' },
		{ value: 'Other Surgery', label: 'Other Surgery' },
		{ value: 'Other', label: 'Other' },
	];
	const [referredToChecked, setReferredToChecked] = React.useState(props.selectedTicket ? !!props.selectedTicket.referredTo : false);
	const [referredToSelection, setReferredToSelection] = React.useState(props.selectedTicket && props.selectedTicket.referredTo ?
		(referralOptions.map(x => x.value).includes(props.selectedTicket.referredTo) ? props.selectedTicket.referredTo : 'Other')
		: null);
	const [referredToInput, setReferredToInput] = React.useState(props.selectedTicket ? props.selectedTicket.referredTo : null);
	const handleReferredToChecked = (event) => {
		setReferredToChecked(event.target.checked);
		if (!event.target.checked) {
			setReferredToSelection(null);
			setReferredToInput(null);
		}
	};

	// procedure aborted
	const procedureAbortedOptions = [
		{ value: 'Equipment Issue', label: 'Equipment Issue' },
		{ value: 'Unstable Patient', label: 'Unstable Patient' },
		{ value: 'Difficult Case/Anatomy', label: 'Difficult Case/Anatomy' },
		{ value: 'Other', label: 'Other' }
	];
	const [procedureAbortedChecked, setProcedureAbortedChecked] = React.useState(props.selectedTicket ? !!props.selectedTicket.procedureAbortedDetails : false);
	const [procedureAbortedSelection, setProcedureAbortedSelection] = React.useState(props.selectedTicket ? props.selectedTicket.procedureAbortedDetails : null);
	const [procedureAbortedInput, setProcedureAbortedInput] = React.useState(props.selectedTicket ? props.selectedTicket.procedureAbortedDetails : null);
	const handleProcedureAbortedChecked = (event) => {
		setProcedureAbortedChecked(event.target.checked);
		if (!event.target.checked) {
			setProcedureAbortedSelection(null);
		};
	};

	// mbb
	const [mbbCptSelected, setmbbCptSelected] = React.useState(false);
	const [mbbCount, setmbbCount] = React.useState(null);

	// clinic/procedure visit estimated time
	const clinicMinuteOptions = [
		{ value: 15, label: "15 Minutes" },
		{ value: 30, label: "30 Minutes" },
		{ value: 45, label: "45 Minutes" },
		{ value: 60, label: "60 Minutes" },
		{ value: 90, label: "90 Minutes" }
	];
	const [clinicVisitTime, setClinicVisitTime] = React.useState(props.selectedTicket ? parseInt(props.selectedTicket.estimatedTime) : null);
	const [procedureStartTime, setProcedureStartTime] = React.useState(null);
	const [procedureEndTime, setProcedureEndTime] = React.useState(null);

	const [selectedAppointment, setSelectedAppointment] = useState(null);

	// cancellation/no-show
	const cancellationOptions = [
		{ value: "No-Show", label: "No-Show" },
		{ value: "Patient Cancellation", label: "Patient Cancellation" },
		{ value: "Active Infection", label: "Active Infection" },
		{ value: "Blood Thinners", label: "Blood Thinners" },
		{ value: "Difficult Case", label: "Difficult Case" },
		{ value: "Financial", label: "Financial" },
		{ value: "Insurance Concerns", label: "Insurance Concerns" },
		{ value: "Provider Preference", label: "Provider Preference" },
		{ value: "Other", label: "Other" },
	];
	const [displayCancellation, setDisplayCancellation] = React.useState(false);
	const [cancellationChecked, setCancellationChecked] = React.useState(false);
	const [cancellationSelection, setCancellationSelection] = React.useState(null);
	const [cancellationInput, setCancellationInput] = React.useState(null);
	const handleCancellationChecked = (event) => {
		setCancellationChecked(event.target.checked);
		if (!event.target.checked) {
			setCancellationInput(null);
			setCancellationSelection(null);
		}
	};

	// const appointments = useQuery(GET_APPOINTMENTS, {
	// 	// fetchPolicy: "no-cache",
	// 	fetchPolicy: "network-only",
	// 	variables: {xml: getXml(date), url: url, headers: JSON.stringify(apptsHeaders)},
	// 	onCompleted: (data) => { console.log(`appointments :: useQuery => onCompleted`); processAppointments(data); } // getAppointments(date)
	// });

	const procedures = useQuery(GET_PROCEDURES, { variables: { filter: '' } }); //, onCompleted: () => getAppointments(date) });
	// const procedures = useQuery(GET_PROCEDURES, { variables: { filter: '{\"type\":\"Legacy\"}' }, onCompleted: () => getAppointments(date) });
	// const procedures = useQuery(GET_PROCEDURES, { onCompleted: () => getAppointments(date) })
	const selectedDate = new Date(date).toISOString()
	const startOfNextDay = dayjs(selectedDate).startOf('day').add(1, 'day').toISOString()
	const queryFilter = JSON.stringify({ user: { id: provider ? provider.id : parseInt(user.id) }, date: { gte: selectedDate, lt: startOfNextDay } })

	// const chargeTickets = useQuery(GET_CHARGE_TICKETS, { variables: { filter: queryFilter, skip: 0, take: 1000 } })

	const [kareoAppointments, setKareoAppointments] = useState([]);

	const [appointmentsSeen, setAppointmentsSeen] = useState([])

	const [createTicket] = useMutation(CREATE_TICKET)
	const [updateTicket] = useMutation(UPDATE_TICKET)
	const [deleteTicket] = useMutation(DELETE_TICKET)
	const [options, setOptions] = useState([]);

	const users = useQuery(GET_USERS);

	const kareoLocationsQuery = useQuery(GET_KAREO_LOCATIONS, { variables: { filter: "" } });
	const [kareoLocations, setKareoLocations] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState({ label: '', key: '' });

	const cptTiersQuery = useQuery(GET_CPT_TIERS, { variables: { filter: "" } });
	const [cptTiers, setCptTiers] = useState([]);

	const studentOptions = [
		{ value: "Participated", label: "Participated" },
		{ value: "Observed", label: "Observed" }
	];
	const [trainerOptions, setTrainerOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);

	const [isClinicChecked, setIsClinicChecked] = useState(false);
	const [isTrainingChecked, setIsTrainingChecked] = useState(false);

	const [trainer, setTrainer] = useState(props.selectedTicket ? props.selectedTicket.trainer : null);
	const [studentType, setStudentType] = useState(props.selectedTicket ? props.selectedTicket.studentType : null);

	const [imaging, setImaging] = useState(props.selectedTicket ? props.selectedTicket.imagingEquipment : null);
	const [imagingCptSelected, setImagingCptSelected] = useState(props.selectedTicket && props.selectedTicket.imagingEquipment ? true : false);

	const [provider, setProvider] = useState(null);

	// referring provider
	const [referredByInput, setReferredByInput] = React.useState(props.selectedTicket ? props.selectedTicket.referredBy : "");
	const [referringProviders, setReferringProviders] = useState([]);
	const [referringProviderMutationFlag, setReferringProviderMutationFlag] = useState(false);
	const [isReferringProviderDialogOpen, setIsReferringProviderDialogOpen] = useState(false);
	const [referringProviderDialogText, setReferringProviderDialogText] = useState("Are you sure you want to create a new referring provider?");
	const [getReferringProviders, { loading, error, data }] = useLazyQuery(GET_REFERRING_PROVIDERS, {
		fetchPolicy: 'cache-and-network',
		onCompleted: (data) => {
			if (data && data.referringproviders) {
				console.log("Returned referring providers: ", data.referringproviders);

				const formattedProviders = [];
				for (let i = 0; i < data.referringproviders.length; i++) {
					const providerOptionSyntax = {
						value: `${data.referringproviders[i].name}`,
						label: `${data.referringproviders[i].name}`,
					};

					formattedProviders.push(providerOptionSyntax);
				}
				setReferringProviders(formattedProviders);
			};
		}
	});
	
	const [createReferringProvider] = useMutation(CREATE_REFERRING_PROVIDER);
	const [referringProviderChecked, setReferringProviderChecked] = useState(props.selectedTicket && props.selectedTicket.referredBy ? true : false);
	const handleReferringProviderChecked = (event) => {
		setReferringProviderChecked(event.target.checked);
		if (!event.target.checked) {
			setReferredByInput("");
		}
	};

	const [similarityOptions, setSimilarityOptions] = useState([])
	const [createPatientInput, setCreatePatientInput] = useState("")
	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [dialogText, setDialogText] = useState("Are you sure you want to create a new patient?");

	const [requestDialogOpen, setRequestDialogOpen] = useState(false);
	const [requestDetails, setRequestDetails] = useState(undefined);
	const [submitRequestBtnDisabled, setSubmitRequestBtnDisabled] = useState(true);

	const [activeTier, setActiveTier] = useState(null);
	const [selectedClinicProcedures, setSelectedClinicProcedures] = useState(props.selectedTicket ? props.selectedTicket.referredBy : []);
	const [hasClinicProceduresChecked, setHasClinicProceduresChecked] = useState(false);
	function handleClinicProcedureChecked(checked) {
		setHasClinicProceduresChecked(checked);
		if (!checked) {
			setSelectedClinicProcedures([]);
			setImaging(null);
		}
	}
	function handleClinicProcedureCpts(cpts) {
		console.log(`CLINIC PROCEDURES = `, cpts);
		setSelectedClinicProcedures(cpts);
	}

	var dataLoading =
		users.loading
		|| cptTiers.loading
		|| kareoLocationsQuery.loading
		|| procedures.loading
		|| isLoading;

	useEffect(() => {
		if (initialData) {
			console.log('loading with initial data...');
			today = new Date(dateFormatter.format(new Date(initialData.date)));
			setDate(`${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`);
			// let loc = mapToKareoLocationByName(initialData.location);
			let loc = mapToKareoLocationById(initialData.locationId);
			if (loc) {
				// { label: '', key: '' }
				setLocation(loc);
				setSelectedLocation(loc);
				const tier = TierUtils.getTier(loc, today);
				setActiveTier(tier);
			}
		}
	}, [kareoLocations]);

	useEffect(() => {
		async function fetchSelectedTicketsApp(hasKareoAppId) {
			if (hasKareoAppId) {
				const [app] = await chargeClient.getAppointments({
					kareoIds: [props.selectedTicket.kareoApptId]
				})
				console.log("App From Ticket KareoApptId: ", app);

				if (!isClinicChecked) {
					if (app) { setProcedureStartTime(dayjs(`${app.date}`)); }
					if (props.selectedTicket.startTime) { setProcedureStartTime(dayjs(`${props.selectedTicket.startTime}`)); }
					if (props.selectedTicket.estimatedTime) {
						// Take date and estimated time on ticket to get visit end time
						const endingDateTime = new Date(new Date(app.utcDate).getTime() + props.selectedTicket.estimatedTime * 60000);
						setProcedureEndTime(dayjs(endingDateTime));
					}
				}

			} else {
				const apps = await chargeClient.getAppointments({
					startDate: `${props.selectedTicket.date.split('T')[0]}T00:00:00.001Z`,
					endDate: `${props.selectedTicket.date.split('T')[0]}T23:59:59.001Z`,
					userIds: [user.id]
				})

				const matchingApp = apps.find(app => app.mrn === props.selectedTicket.mrn);
				console.log("Matching App: ", matchingApp);

				if (matchingApp) {
					// setProcedureStartTime(dayjs(new Date(`${matchingApp.utcDate}`)));
					setProcedureStartTime(dayjs(`${matchingApp.date}`));

					if (props.selectedTicket.estimatedTime) {
						// Take date and estimated time on ticket to get visit end time
						const endingDateTime = new Date(new Date(matchingApp.utcDate).getTime() + props.selectedTicket.estimatedTime * 60000);
						setProcedureEndTime(dayjs(endingDateTime));
					}
				}

				if (props.selectedTicket.startTime) {
					setProcedureStartTime(dayjs(`${props.selectedTicket.startTime}`));
					if (props.selectedTicket.estimatedTime) {
						// Take date and estimated time on ticket to get visit end time
						const endingDateTime = new Date(new Date(`${props.selectedTicket.startTime}`).getTime() + props.selectedTicket.estimatedTime * 60000);
						setProcedureEndTime(dayjs(endingDateTime));
					}
				}


			}
		}

		if (props.selectedTicket && props.selectedTicket.kareoApptId) {
			fetchSelectedTicketsApp(true);
		} else if (props.selectedTicket && !props.selectedTicket.kareoApptId) {
			fetchSelectedTicketsApp(false);
		}
	}, []);

	// useEffect(() => {
	// 	updatePatientList();
	// }, [isLoading]);

	// Set values from selected ticket for editing
	useEffect(() => {

		async function fetchData() {
			let date = selectedDate;
			let nextDay = dayjs(date).startOf('day').add(1, 'day').subtract(1, 'second').toISOString();
			let providerId = provider ? parseInt(provider.id) : parseInt(user.id);
			let kareoId = provider ? provider.kareoId : user.kareoId;
			let userId = provider ? provider.id : user.id;
			let startDate = new Date(`${date}`);
			// let queryFilter = JSON.stringify({ user: { id: provider ? parseInt(provider.id) : parseInt(user.id) }, date: { gte: date, lt: nextDay } });
			console.log(`APPTS:: ProviderId = ${userId}, Start = ${startDate.toISOString().split('T')[0]}T00:00:00.001Z, End = ${startDate.toISOString().split('T')[0]}T23:59:59.001Z`);

			const [users, locations, cptTiers, tickets, appointments] = await Promise.all([
				getUsers(),
				getLocations(),
				getCptTiers(),
				dataProvider.getTickets(providerId, date, nextDay),
				// dataProvider.getAppointments(date, nextDay, null, kareoId),
				chargeClient.getAppointments({
					startDate: `${startDate.toISOString().split('T')[0]}T00:00:00.001Z`, // date,
					endDate: `${startDate.toISOString().split('T')[0]}T23:59:59.001Z`, // nextDay,
					userIds: [userId]
				})
			]);
			updatePatientList(date, tickets, appointments);
			setIsLoading(false);

			if (props.selectedTicket) {
				setProvider({
					...props.selectedTicket.user,
					label: `${props.selectedTicket.user.firstName} ${props.selectedTicket.user.lastName}`,
					value: `${props.selectedTicket.user.firstName} ${props.selectedTicket.user.lastName}`
				});
			}
		}
		fetchData();

		if (props.selectedTicket) {
			let newInsurance = []
			let newProcedures = []
			let newDefaultProcedures = [];
			let newClinicProcedures = [];

			props.selectedTicket.insurance.split(',').map((value) => {
				if (insuranceOptions.includes(value))
					newInsurance.push(value)
				else {
					setOtherInsurance(true)
					setOtherInsuranceValue(value)
				}
				return 0
			})

			props.selectedTicket.chargeTicketCpts.sort((a,b) => a.sequence - b.sequence).map(cpt => {

				if (cpt.cptType === 'Default') {
					if (cpt.cpt.class === "MED IMAGING") setImagingOrdered(true);
					if (cpt.cpt.class === "CRNA PAIN MANAGEMENT PROCEDURES") setPxOrdered(true);
					if (cpt.cpt.id === "149") setLabOrdered(true);
					if (cpt.cpt.id === "150") setPtOrdered(true);
					if (cpt.cpt.id === "161") setBlindInj(true);
					if (!["149", "150", "161"].includes(cpt.cpt.id)) newProcedures[cpt.sequence] = cpt.cpt;
					if (`${cpt.cpt.code}`.startsWith('99')) setIsClinicChecked(true);

					// only consider CPTs not handled by checkboxes
					if (!["149", "150", "161"].includes(cpt.cpt.id)) newDefaultProcedures.push(cpt.cpt);
				}
				if (cpt.cptType === 'ClinicProcedure') { newClinicProcedures.push(cpt.cpt) }

				return 0;
			});

			console.log(`[ChargeTicket] :: selected ticket => props.selectedTicket = `, props.selectedTicket);
			console.log(`[ChargeTicket] :: selected ticket => newProcedures CPTs = `, newProcedures);

			console.log(`[ChargeTicket] :: selected ticket => default CPTs = `, newDefaultProcedures);
			console.log(`[ChargeTicket] :: selected ticket => ClinicProcedure CPTs = `, newClinicProcedures);


			setInsurance(newInsurance)
			// setCompletedProcedures(newProcedures);
			setCompletedProcedures(newDefaultProcedures);
			setHasClinicProceduresChecked(newClinicProcedures.length > 0);
			setSelectedClinicProcedures(newClinicProcedures);
		}
	}, [props.selectedTicket])

	useEffect(() => {
		if (location.id) {
			fetchReferringProviders(location.id);
		}
	}, [location.id])

	async function fetchReferringProviders(locationId) {
		try {
			const result = await getReferringProviders({ variables: { locationId: locationId } });
		} catch (error) {
			console.log("Error retrieving referring providers: ", error);
		}
	}

	function handleOpenReferringProviderDialog() {
		setIsReferringProviderDialogOpen(true);
	};

	function similarityTest(str, arr) {
		const inputString = str.toUpperCase()
		const similarities = []

		for (let i = 0; i < arr.length; i++) {
			const similarity = stringSimilarity.compareTwoStrings(inputString, arr[i].value.toUpperCase())

			if (similarity > 0.3) {
				similarities.push(arr[i].value)
			}
		}

		setSimilarityOptions(similarities)
		return similarities
	}

	function handleOpen() {
		setIsDialogOpen(true)
	}

	const handleClick = () => {
		// console.info('You clicked the Chip.');
	};

	const [ticketLockChange, setTicketLockChange] = useState(false);
	const [isLocking, setIsLocking] = useState(false);
	const handleTicketLockClick = (event) => {
		updateTicketLock(true);
	};
	const handleTicketUnlockClick = (event) => {
		updateTicketLock(false);
	};

	const onProviderChange = async (provider) => {
		// console.log(`provider = `, provider);
		setProvider(provider);
		// getTickets(provider);
		// setTimeout(updatePatientList());

		// clear the form
		setSelectedLocation('');
		setActiveTier(null);
		setLocation({ label: '', key: '' });
		setPatientName('');
		setMrn('');
		setReferringProviders([]);
		setKareoApptId(null);

		// await getTickets(provider);
		// await getAppointments(`${date}`, provider);

		let theDate = date ? date : selectedDate;
		refreshTicketsData(theDate, provider);
	}

	function handleClose(value, reason) {
		if (reason === "backdropClick") {
			setIsDialogOpen(false)
			setPatientName("")
			return
		}

		setIsDialogOpen(false)
		handleModalSelection(value)
	}

	function handleReferringProviderClose(value, reason) {
		if (reason === "backdropClick") {
			setIsReferringProviderDialogOpen(false);
			setReferredByInput("");
			return;
		};

		setIsReferringProviderDialogOpen(false);
		setReferringProviderMutationFlag(true);
	};

	function handleReferringProviderSimilarityOption(value) {
		setIsReferringProviderDialogOpen(false);
		setReferredByInput(value);
	};

	async function handleNewReferringProviderMutation() {
		if (user.id && location.id && referredByInput) {
			try {
				const locationId = parseInt(location.id);
				const userId = parseInt(user.id);
				const newReferringProvider = {
					locationId: locationId,
					userId: userId,
					name: referredByInput,
					encounterType: isClinicChecked ? "Clinic" : "Procedure"
				};
				const result = await createReferringProvider({ variables: { referringprovider: newReferringProvider } });
				console.log("Referring provider created");

			} catch (error) {
				console.log("Error creating new referring provider: ", error);
				setReferredByInput("");
				alert("There was an error creating this referring provider.")
			}
		};
	};

	function handleModalSelection(value) {
		patients.map(chargeTicket => {
			if (chargeTicket.patientName === value) {
				let kareoLocation = mapToKareoLocationByName(chargeTicket.location);
				setLocation(kareoLocation);
				setSelectedLocation(kareoLocation);
				setServiceLocation(chargeTicket.serviceLocationName);
				setMrn(chargeTicket.mrn);
				const tier = TierUtils.getTier(kareoLocation, selectedDate);
				setActiveTier(tier);
			}
			return null
		}).join('');
		setPatientName(value);
		fetchPatientTickets(null, value);
	}

	async function getUsers() {
		try {
			let allUsers = await users.refetch();
			// console.log('users = ', allUsers);
			if (!allUsers.data) { return; }
			let userList = allUsers.data.users.users.filter(user => user.isActive);

			// do not show admin/training users in provider selector in PROD
			if (envName === 'PROD') {
				userList = userList.filter(u =>
					!PermissionsProvider.hasRole(u, HPM_ROLE.Training)
					&& !PermissionsProvider.hasRole(u, HPM_ROLE.Corporate)
				);
			}

			userList = userList
				.sort((a, b) => {
					var textA = a.firstName.toUpperCase();
					var textB = b.firstName.toUpperCase();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				})
				.map(user => {
					return {
						value: `${user.firstName} ${user.lastName}`,
						label: `${user.firstName} ${user.lastName}`,
						id: user.id,
						kareoId: user.kareoId,
						roles: user.roles
					};
				});
			console.log('userList = ', userList);
			userList = userList.filter(x => x.value !== null);
			setTrainerOptions(userList);
		} catch (error) {
			console.log(error);
			return;
		}
	}

	async function getLocations() {
		try {
			let response = await kareoLocationsQuery.refetch().then((result) => {
				setKareoLocations(result.data.kareoLocations);
				// console.log(`locations = `, result);
				let locationList = result.data.kareoLocations
					.filter(x => x.kareoId && `${x.id}` !== `0`)// && x.isActive)
					.map(location => {
						return { value: `${location.id}`, label: `${location.practiceName.trim()}` };
					});
				locationList = locationList.filter(x => x.value !== null);
				const sortedList = locationList.sort((a, b) => {
					var textA = a.label.toUpperCase();
					var textB = b.label.toUpperCase();
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				})
				setLocationOptions(sortedList);

				if (props.selectedTicket) {
					setMrn(props.selectedTicket.mrn);
					let foundLocation = result.data.kareoLocations.find(x => x.practiceName.trim() === props.selectedTicket.location.trim());
					setLocation(foundLocation);
					if (foundLocation) {
						// var foundItem = locationList.find(x => x.value === `${foundLocation.id}`);
						setSelectedLocation(foundLocation);
						const tier = TierUtils.getTier(foundLocation, selectedDate);
						setActiveTier(tier);
					}
				}
			});

		} catch (error) {
			console.log(error);
		}
	}

	async function getCptTiers() {
		try {
			let response = await cptTiersQuery.refetch();
			// console.log(`cptTiers = `, response);
			if (response.data) {
				setCptTiers(response.data.cptTiers);
			}
			return;
		} catch (error) {
			console.log(error);
			return;
		}
	}

	function mapToKareoLocationByName(name) {
		if (!kareoLocations) { return name; }
		let foundLocation = kareoLocations.find(x => x.practiceName.trim() === name.trim());
		return foundLocation ? foundLocation : name;
	}

	function mapToKareoLocationById(id) {
		console.log(`selected: ${id}`);
		if (!kareoLocations) { return id; }
		let foundLocation = kareoLocations.find(x => `${x.id}` === `${id}`);
		return foundLocation ? foundLocation : null;
	}

	function updatePatientList(date, theTickets, theAppointments) {

		// tickets = tickets.filter(x => getDateKey(new Date(`${x.date}`)) === getDateKey(new Date(`${date}`)));
		// appointments = appointments.filter(x => getDateKey(new Date(`${x.date}`)) === getDateKey(new Date(`${date}`)));

		let tickets = theTickets.map(x => {
			return {
				...x,
				dateKey: getDateKey(new Date(`${x.date}`)),
				fixedDate: new Date(`${x.date}`)
			};
		});
		let appointments = theAppointments.map(x => {
			// let [date, time] = x.date.replace('Z', '').split('T');
			// let fixedDate = `${date} ${time} PST`;
			// let fixedDate = x.date.replace('Z', '-08:00');
			let fixedDate = x.date.toISOString().replace('Z', '-08:00');
			return {
				...x,
				// dateKey: getDateKey(new Date(Date.parse(fixedDate))),
				dateKey: getDateKey(x.date),
				fixedDate: new Date(Date.parse(fixedDate))
				// dateKey: getDateKey(new Date(Date.parse(x.date))),
				// fixedDate: new Date(Date.parse(x.date))
				// dateKey: getDateKey(new Date(Date.parse(`${x.date.replace('Z', '')} PST`))),
				// fixedDate: new Date(Date.parse(`${x.date.replace('Z', '')} PST`))
			};
		});

		console.log('tickets = ', tickets);
		console.log('appointments = ', appointments);
		
		// let tst = getDateKey(new Date(`${date}`));
		// console.log(`filtering by date/key = ${date} | ${tst}`, new Date(`${date}`));

		// tickets = tickets.filter(x => `${x.dateKey}` === getDateKey(new Date(`${date}`)));
		// appointments = appointments.filter(x => `${x.dateKey}` === getDateKey(new Date(`${date}`)));

		// console.log('tickets AFTER = ', tickets);
		// console.log('appointments AFTER = ', appointments);

		setPatients(appointments);
		let appointmentsToday = appointments.filter(x =>
			x.appointmentStatus !== KareoStatus.CANCELLED
			&& x.appointmentStatus !== KareoStatus.RESCHEDULED
			&& x.appointmentStatus !== KareoStatus.NOSHOW
			&& x.appointmentStatus !== KareoStatus.NO_SHOW
		);
		setPatientsToday(appointmentsToday);

		const matchingTickets = [];
		const totalTickets = tickets;
		console.log('totalTickets = ', totalTickets);
		const patientsLeft = [];
		console.log('totalPatients = ', appointments);

		if (props.selectedTicket) {
			for (let i = 0; i < appointmentsToday.length; i++) {
				patientsLeft.push({ value: appointmentsToday[i].patientName, label: appointmentsToday[i].patientName, kareoId: appointmentsToday[i].kareoId })
			}
		} else {
			for (let i = 0; i < appointmentsToday.length; i++) {
				let foundPatientMatch = false;

				for (let j = 0; j < totalTickets.length; j++) {
					if (totalTickets[j].mrn === appointmentsToday[i].mrn) {
						matchingTickets.push(totalTickets[j]);
						foundPatientMatch = true;
						break;
					}
				}

				if (!foundPatientMatch) patientsLeft.push({ value: appointmentsToday[i].patientName, label: appointmentsToday[i].patientName, kareoId: appointmentsToday[i].kareoId });
			}
		}

		console.log('matchingTickets = ', matchingTickets);
		console.log('patientsLeft =', patientsLeft);
		setOptions(patientsLeft);

		setAppointmentsSeen(matchingTickets);
	}

	function handleLocationSelected(evt, option) {
		let kareoLoc = mapToKareoLocationById(evt.value);
		setSelectedLocation(kareoLoc);
		const tier = TierUtils.getTier(kareoLoc, selectedDate);
		setActiveTier(tier);
		setLocation(kareoLoc);
		setReferredByInput("");
	}

	function handleReferredToSelected(evt, option) {
		console.log(`handleReferredToSelected: ${evt.target.value}`, evt);
		setReferredToSelection(evt.target.value);
		setReferredToInput(evt.target.value);
	}

	function handleProcedureAbortedSelected(evt, option) {
		console.log(`handleProcedureAbortedSelected: ${evt.target.value}`, evt);
		setProcedureAbortedSelection(evt.target.value);
		setProcedureAbortedInput(evt.target.value);
	}

	function handleCancellationSelected(evt, option) {
		console.log(`handleCancellationSelected: ${evt.target.value}`, evt);
		setCancellationSelection(evt.target.value);
		setCancellationInput(evt.target.value);
	};

	function handleClinicVisitTime(evt) {
		console.log(`Clinic Estimated Visit Time: ${evt.target.value} minutes`);
		setClinicVisitTime(parseInt(evt.target.value));
	};

	function handleProcedureStartTime(evt) {
		// console.log(`handleProcedureStartTime: `, evt.toDate().toISOString());
		if (evt) {
			const formattedStartTime = evt.format('HH:mm');
			console.log(`Formatted Procedure Start Time: ${formattedStartTime}`);
			setProcedureStartTime(evt);
		}
	};

	function handleProcedureEndTime(evt) {
		if (evt) {
			const formattedEndTime = evt.format('HH:mm');
			console.log(`Formatted Procedure End Time: ${formattedEndTime}`);
			setProcedureEndTime(evt);
		}
	};

	async function handleDateChange(newDate) {
		setDate(newDate.getMonth() + 1 + "/" + newDate.getDate() + "/" + newDate.getFullYear());

		// clear the form
		setSelectedLocation('');
		setActiveTier(null);
		setLocation({ label: '', key: '' });
		setPatientName('');
		setMrn('');
		setKareoApptId(null);
		setProcedureStartTime(null);
		setProcedureEndTime(null);
		setReferredByInput("");
		setReferringProviders([]);

		let theProvider = provider ? provider : user;
		refreshTicketsData(newDate, theProvider);
	}

	async function refreshTicketsData(newDate, provider) {
		setIsLoading(true);

		let date = newDate ? newDate : selectedDate;
		let nextDay = dayjs(date).startOf('day').add(1, 'day').subtract(1, 'second').toISOString();
		let providerId = provider ? parseInt(provider.id) : parseInt(user.id);
		let kareoId = provider ? provider.kareoId : user.kareoId;
		let userId = provider ? provider.id : user.id;
		let startDate = new Date(`${date}`);
		// let queryFilter = JSON.stringify({ user: { id: provider ? parseInt(provider.id) : parseInt(user.id) }, date: { gte: date, lt: nextDay } });
		try {

			console.log(`APPTS:: ProviderId = ${userId}, Start = ${startDate.toISOString().split('T')[0]}T00:00:00.001Z, End = ${startDate.toISOString().split('T')[0]}T23:59:59.001Z`);

			const [tickets, appointments] = await Promise.all([
				await dataProvider.getTickets(providerId, date, nextDay),
				// await dataProvider.getAppointments(date, nextDay, null, kareoId),
				chargeClient.getAppointments({
					startDate: `${startDate.toISOString().split('T')[0]}T00:00:00.001Z`,// date,
					endDate: `${startDate.toISOString().split('T')[0]}T23:59:59.001Z`, // nextDay,
					userIds: [userId]
				})
			]);
			updatePatientList(date, tickets, appointments);
		} catch (err) {
			console.error(`Error Refreshing Data!`);
		} finally {
			setIsLoading(false);
		}
	}



	function handlePatientChange(event) {
		let value = null;
		if (!event.target) {
			value = event.label;
			setKareoApptId(null);
		} else {
			value = event.target.value;
		}

		patients.map(appt => {
			if (appt.patientName === value) {
				let kareoLocation = mapToKareoLocationByName(appt.location);
				setLocation(kareoLocation); // chargeTicket.location);
				setSelectedLocation(kareoLocation);
				const tier = TierUtils.getTier(kareoLocation, selectedDate);
				setActiveTier(tier);
				setServiceLocation(appt.serviceLocationName);
				setMrn(appt.mrn);
				// setProcedureStartTime(dayjs(new Date(`${appt.fixedDate}`)));
				setProcedureStartTime(dayjs(`${appt.date}`));
				console.log(`chargeTicket KareoId set to ${appt.kareoId}`, appt);
				setKareoApptId(appt.kareoId);
				fetchPatientTickets(appt.mrn, appt.patientName);
				setSelectedAppointment(appt)
			}
			return null;
		}).join('');
		setPatientName(value);
		setDisplayCancellation(true);
		setCancellationChecked(false);
		setCancellationInput(null);
		setCancellationSelection(null);
	}

	function handleReferringProviderChange(event) {
		console.log("Referring provider selected: ", event.value);
		setReferredByInput(event.value);
		setReferringProviderMutationFlag(false);
	}

	async function fetchPatientTickets(mrn, patientName, forceClinic) {
		if (!patientName) { return; }
		let endDate = date ? new Date(`${date}`) : new Date(`${selectedDate}`);
		let startDate = new Date(`${dayjs(endDate).startOf('day').subtract(1, 'year').toISOString()}`);
		console.log(`Fetching Patient (${mrn}) tickets | ${startDate} - ${endDate}`);

		const filter = {
			// mrns: [mrn],
			patientNames: [patientName],
			startDate: startDate.toISOString(),
			endDate: endDate.toISOString()
		};
		const tickets = await chargeClient.getTickets(filter);
		console.log(`fetchPatientTickets response = `, tickets);

		const rolesToUse = provider === null ? user.roles : provider.roles;
		const rolesList = rolesToUse.map(x => x.name);
		let theRole = 'Clinic';
		if (rolesList.includes('Procedure') && !isClinicChecked && !forceClinic) { theRole = 'Procedure'; }
		let notificationData = checkForNotifications(tickets, theRole);
		console.log(`[+] notificationData = `, notificationData);
		setPatientNotifications(notificationData);
	}

	async function handleClinicChecked(val) {
		setIsClinicChecked(val);

		// If clinic is selected reset procedures state. If clinic is unselected reset misc options state
		if (val) {
			setDescription(null);
			setProcedureAbortedChecked(false);
			setProcedureAbortedSelection(null);
			setProcedureAbortedInput(null);
			setProcedureStartTime(null);
			setProcedureEndTime(null);
		} else {
			setBlindInj(false);
			setPtOrdered(false);
			setLabOrdered(false);
			setPxOrdered(false);
			setClinicVisitTime(null);
		};

		await fetchPatientTickets(null, patientName, val);
	}

	function resetForm(keepSpinning) {
		var children = document.getElementById("insuranceGroup") ? document.getElementById("insuranceGroup").children : [];
		for (var i = 0; i < children.length; i++) {
			var child = children[i]
			child.classList.remove("text-white")
			child.classList.remove("bg-blue-500")
			child.classList.add("bg-transparent")
			child.classList.add("text-blue-700")
		}
		setSelectedLocation("");
		setActiveTier(null);
		setLocation({ label: '', key: '' });
		setMrn(null);
		setDescription(null);
		setPatientName(null);
		setInsurance([]);
		setCompletedProcedures([]);
		setOtherInsurance(false);
		setOtherInsuranceValue(null);
		setPtOrdered(false);
		setLabOrdered(false);
		setPxOrdered(false);
		setImagingOrdered(false);
		setImagingCptSelected(false);
		setProvider(null);
		setImaging(null);
		setIsClinicChecked(false);
		setReferredToChecked(false);
		setReferredToSelection(null);
		setReferredToInput(null);
		setWarningNotifications([]);
		setPatientNotifications([]);
		setProcedureAbortedChecked(false);
		setProcedureAbortedSelection(null);
		setProcedureAbortedInput(null);
		setmbbCptSelected(false);
		setmbbCount(null);
		setClinicVisitTime(null);
		setProcedureStartTime(null);
		setProcedureEndTime(null);
		setReferredByInput("");
		setReferringProviders([]);
		setDisplayCancellation(false);
		setCancellationChecked(false);
		setCancellationInput(null);
		setCancellationSelection(null);
		setReferringProviderMutationFlag(false);
		if (!keepSpinning)
			setSpinning(false);
	}

	function handleInsuranceClick(event) {
		if (event.target) {
			let value = event.target.value
			let newInsurance = insurance

			if (event.target.id === "Other") {
				setOtherInsurance(!otherInsurance)
			}
			else if (newInsurance.includes(value)) {
				newInsurance.splice(newInsurance.indexOf(value), 1)
			}
			else {
				newInsurance.push(value)
			}

			setInsurance(newInsurance)
			let temp = count // Unnecessary but hate seeing the lint warning from not using count
			setCount(temp + 1) // Super hacky way of forcing a rerender to display new insurance button css classes
		}
	}

	async function cancel(e) {
		setSpinning(true)
		await props.refetch()
		props.setSelectedTicket(null)
	}

	function handleImagingChange(event) {
		// if (procedures.data.cpts.cpts.some(x => imagingCpts.includes(`${x.code}`))) {
		// 	console.log(`Imaging CPT selected`);
		// }
		console.log(`Imaging CPT selected: `, event);
		setImaging(event.target.value);
		localStorage.setItem(RFA_EQUIPMENT_KEY, event.target.value);
	}

	const [selectedIcdCodes, setSelectedIcdCodes] = useState(
		props.selectedTicket && props.selectedTicket.chargeTicketIcds && props.selectedTicket.chargeTicketIcds.length
		? props.selectedTicket.chargeTicketIcds.map(x => x.icdCode)
		: []);
	function handleIcdChange(codes) {
		setSelectedIcdCodes(codes);
	}

	async function handleSubmit(event, isChangeRequest) {
		event.preventDefault();

		try {


			setSpinning(true);
			var cpts = [];
			var cptClasses = [];
			var errors = [];

			completedProcedures.map((procedure) => {
				cpts.push({ id: procedure.id, code: procedure.code });
				cptClasses.push(procedure.class);
				return false;
			})

			if (otherInsurance && otherInsuranceValue !== '' && otherInsuranceValue !== null) {
				insurance.push(otherInsuranceValue);
			}


			if (!validateChargeTicket(user, procedures.data.cpts.cpts.filter(x => x.type === (location && location.chargeType !== 'Legacy') ? 'Default' : 'Legacy'), cpts, isClinicChecked)) { // cpts has internal IDs only
				setSpinning(false);
				return;
			}

			let icds = selectedIcdCodes.map(icd => {
				return { id: icd.id, code: icd.code };
			});

			if (!PermissionsProvider.hasRole(user, 'Training') && !isTrainingChecked) { // user.role !== "4") {
				if (location === '' || location === null) { errors.push("Location cannot be blank.") }
				if (patientName === '' || patientName === null) { errors.push("Patient cannot be blank.") }
				if (mrn === '' || mrn === null) { errors.push("MRN cannot be blank.") }
				if (insurance.length === 0) { errors.push("At least one Insurance must be added.") }
			}
			// if (["3", "4"].includes(user.role) && !isClinicChecked && (description === '' || description === null)) { errors.push("Description cannot be blank.") }
			if ((PermissionsProvider.hasAnyRoles(user, ['Procedure','Training', 'Corporate']))
				&& !isClinicChecked
				&& (description === '' || description === null))
			{ errors.push("Description cannot be blank.") }
			if (cpts.length === 0) { errors.push("At least one CPT must be added.") }
			if (imagingOrdered && !cptClasses.includes("MED IMAGING")) { errors.push("Please add the CPT of ordered imaging.") }
			if (pxOrdered && !cptClasses.includes("CRNA PAIN MANAGEMENT PROCEDURES")) { errors.push("Please add the CPT of ordered procedure.") }
			if (labOrdered) { cpts.push({ id: 149 }) }
			if (ptOrdered) { cpts.push({ id: 150 }) }
			if (blindInj) { cpts.push({ id: 161 }) }

			let estimatedTime = null;
			if (procedureStartTime && procedureEndTime && !isClinicChecked) {
				const convertedStartTime = procedureStartTime.toDate();
				const convertedEndTime = procedureEndTime.toDate();
				// Get time difference in minutes
				estimatedTime = Math.round((convertedEndTime - convertedStartTime) / (1000 * 60));
				if (estimatedTime < 0) {
					errors.push("Please correct procedure times. Start time is after end time.")
				}
			} else if (clinicVisitTime) {
				estimatedTime = clinicVisitTime;
			}

			// // handle clinic procedures (tier 6)
			// if (cpts.length && selectedClinicProcedures && selectedClinicProcedures.length) {
			// 	if (cpts[0].code.startsWith('99')) {
			// 		const firstCpt = cpts.shift();
			// 		cpts = [firstCpt, ...selectedClinicProcedures, ...cpts];
			// 	}
			// }

			const finalClinicProcs = selectedClinicProcedures.length ? selectedClinicProcedures.map(cpt => { return {id: cpt.id } }) : [];

			if (errors.length === 0) {
				var ticketDate = new Date(date);
				ticketDate.setHours(12, 0, 0, 0); // set the hours so times do not default to midnight UTC in their TZ
				const userIDToUse = provider === null ? user.id : provider.id
				var newTicket = {
					user: props.selectedTicket ? props.selectedTicket.user.id : userIDToUse,
					date: ticketDate,
					location: (location && location.practiceName) ? location.practiceName.trim() : "HPM Training",
					patientName: patientName ? patientName.trim() : "HPM Training",
					mrn: mrn ? mrn.trim() : "0000",
					insurance: insurance.toString(),
					procedures: description,
					cpts: cpts,
					icds: icds,
					clinicProcedureCpts: finalClinicProcs,
					createdDate: (props.selectedTicket && props.selectedTicket.createdDate) ? new Date(props.selectedTicket.createdDate) : new Date(),
					modifiedDate: new Date(),
					serviceLocationName: serviceLocation ? serviceLocation : 'N/A',
					trainer: trainer,
					studentType: studentType,
					locationId: location ? location.id : null,
					imagingEquipment: imagingCptSelected && imaging ? imaging : null,
					kareoApptId: props.selectedTicket && props.selectedTicket.kareoApptId ? props.selectedTicket.kareoApptId : kareoApptId,
					referredTo: referredToInput,
					procedureAbortedDetails: procedureAbortedInput,
					mbbCount: mbbCount,
					estimatedTime: estimatedTime,
					startTime: procedureStartTime ? procedureStartTime : null,
					isLocked: props.selectedTicket ? props.selectedTicket.isLocked : false,
					referredBy: referredByInput
				}


				if (isChangeRequest) {

					const userToUse = provider === null ? user : provider;
					newTicket = { ...newTicket, id: props.selectedTicket.id };
					newTicket.modifiedBy = user.id;
					newTicket.modifiedDate = new Date();
					newTicket.user = userToUse;
					newTicket.cptList = completedProcedures.filter(x => !!x);

					cpts.forEach(cpt => {
						const existingCpt = newTicket.cptList.find(x => `${x.id}` === `${cpt.id}`);
						if (!existingCpt) {
							const cptObject = procedures.data.cpts.cpts.find(x => `${x.id}` === `${cpt.id}`);
							if (cptObject) {
								newTicket.cptList.push(cptObject);
							}
						}
					});

					// newTicket.imagingOrdered = imagingOrdered;
					// newTicket.pxOrdered = pxOrdered;
					// newTicket.labOrdered = labOrdered;
					// newTicket.ptOrdered = ptOrdered;
					// newTicket.blindInj = blindInj;
					// newTicket.isClinic = isClinicChecked || cpts.some(x => x.code.startsWith('99'));

					let changeRequest = {
						chargeTicketId: newTicket.id,
						requestByUserId: props.selectedTicket.user.id,
						requestDate: new Date(),
						requestReason: requestDetails,
						ticketData: newTicket
					};

					console.log(`Requesting change to ticket: `, changeRequest);

					await chargeClient.saveTicketChangeRequest(changeRequest);

					alert("Request sent!");
					cancel(event);

				} else {
					console.log(`Saving ticket: `, newTicket);

					if (referringProviderMutationFlag) {
						handleNewReferringProviderMutation();
					}

					if (props.selectedTicket) {
						newTicket = { ...newTicket, id: props.selectedTicket.id };
						newTicket.modifiedBy = user.id;
						newTicket.modifiedDate = new Date();
						await updateTicket({ variables: { chargeTicket: newTicket } });
						alert("Edit saved!");
						cancel(event);
						EventBus.dispatch(ChargeEvents.EDITED, newTicket);
					}
					else {
						await createTicket({ variables: { chargeTicket: newTicket } });
						alert("Charge ticket submitted!");
						// chargeTickets.refetch()
						resetForm(false);
						let theDate = date ? date : selectedDate;
						let theProvider = provider ? provider : user;
						refreshTicketsData(theDate, theProvider);
						EventBus.dispatch(ChargeEvents.SAVED, newTicket);
					}
				}
			}
			else {
				alert("Please correct the following issues before submitting: \n" + errors.join("\n"))
				setSpinning(false)
			}
		} catch (err) {
			console.error(err);
			alert(`Error saving ticket: ${err.message}`);
		} finally {
			setSpinning(false);
		}
	}

	async function updateTicketLock(setLocked) {
		if (!props.selectedTicket) { return; }
		try {
			setIsLocking(true);
			if (setLocked) {
				await chargeClient.lockTickets({
					ids: [props.selectedTicket.id]
				});
			} else {
				await chargeClient.unlockTickets({
					ids: [props.selectedTicket.id]
				});
			}

			props.selectedTicket.isLocked = setLocked;
			setTicketLockChange(!ticketLockChange);

		} catch (err) {
			console.error(err);
			alert(`Error locking/unlocking ticket: ${err.message}`);
		} finally {
			setIsLocking(false);
		}
	}

	async function handleDelete(e, id) {
		e.preventDefault()
		setSpinning(true)
		if (window.confirm("Are you sure you want to delete this ticket?")) {
			await deleteTicket({ variables: { id: id } })
			cancel(e)
		}
		else {
			setSpinning(false)
		}
	}

	const handleCancelRequest = () => {
		setRequestDialogOpen(false);
	};

	const handleSubmitRequest = (event) => {
		handleSubmit(event, true);
	};

	const handleRequestInputChange = (e) => {
		console.log(`handleRequestInputChange`);
		e.stopPropagation();
		setRequestDetails(e.target.value);
		setSubmitRequestBtnDisabled(!e.target.value);
	};

	function getMatchingNotifications(newProcedures) {
		let matchingProcedures = {};
		newProcedures.forEach(newProcedure => {
			patientNotifications.forEach(x => {
				if (x.total !== 0) {
					let patientNotification = { ...x };
					// // exact match
					// if (newProcedure.code === patientNotification.code) {
					// 	matchingProcedures.push(patientNotification);
					// }

					// match any code from the group
					patientNotification.baseCpts.forEach(code => {
						if (newProcedure.code.startsWith(code)) {
							let foundGroup = matchingProcedures[patientNotification.name];
							if (!foundGroup) { foundGroup = matchingProcedures[patientNotification.name] = patientNotification; }
							if (!foundGroup.codes) { foundGroup.codes = []; }
							foundGroup.codes.push(newProcedure.code);
						}
					});
				}
			});
		});

		let results = Object.values(matchingProcedures);
		results.forEach(x => {
			x.code = x.codes.join(',');
		});

		return results;
	};

	const EditButtons = () => {
		if (spinning)
			return (
				<svg id="spinner" className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
					<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
				</svg>
			)
		else if (props.selectedTicket)
			return (
				<>
					{!!imagingCptSelected || (!blindInj && !!selectedClinicProcedures.length) && (
						<>
							<FormControl required variant="outlined" className={classes.formControl}>
								<InputLabel id="demo-simple-select-outlined-label">RFA Equipment</InputLabel>
								<Select
									labelId="imaging-select-label"
									id="imaging-select"
									label="RFA Equipment"
									onChange={(event) => {
										handleImagingChange(event);
										localStorage.setItem(RFA_EQUIPMENT_KEY, event.target.value);
									}}
									value={imaging}
									required
								>
									<MenuItem value={'Avanos'}>Avanos</MenuItem>
									<MenuItem value={'Stryker'}>Stryker</MenuItem>
								</Select>
							</FormControl>
							<br />
							<br />
						</>
					)}


					<div style={{ display: 'flex', gap: '8px' }}>

						{!!props.selectedTicket.isLocked ? (

							<>
								<Tooltip title="Request change to LOCKED ticket" placement="top">
									<div>
										<Button color="primary" variant="contained" onClick={(event) => setRequestDialogOpen(true)}>
											{/* {props.selectedTicket.isLocked ? 'LOCKED' : 'Save'} */}
											Request Change
										</Button>
									</div>
								</Tooltip>
							</>

						) : (

							<Tooltip title={props.selectedTicket.isLocked ? 'LOCKED' : 'Save changes'} placement="top">
								<div>
									<Button color="primary" variant="contained" disabled={props.selectedTicket.isLocked} onClick={(event) => handleSubmit(event, false)} id="submit">
										{/* {props.selectedTicket.isLocked ? 'LOCKED' : 'Save'} */}
										Save
									</Button>
								</div>
							</Tooltip>
						)}

						{' '}

						<Tooltip title={props.selectedTicket.isLocked ? 'LOCKED' : 'Delete ticket'} placement="top">
							<div>
								<Button color="secondary" onClick={(event) => { handleDelete(event, props.selectedTicket.id) }} id="delete" variant="contained" disabled={props.selectedTicket.isLocked}>
									Delete
								</Button>
							</div>
						</Tooltip>

						{' '}

						<Tooltip title="Cancel changes" placement="top">
							<Button onClick={(event) => { cancel(event) }} id="cancel" variant="contained">
								Cancel
							</Button>
						</Tooltip>

					</div>

				</>
			)
		else
			return (
				<>

					{!!imagingCptSelected || (!blindInj && selectedClinicProcedures.length)  ? (
						<>
							<FormControl variant="outlined" className={classes.formControl}>
								<InputLabel id="demo-simple-select-outlined-label">RFA Equipment</InputLabel>
								<Select
									labelId="imaging-select-label"
									id="imaging-select"
									label="RFA Equipment"
									onChange={(event) => {
										handleImagingChange(event);
									}}
									value={imaging}
									required
								>
									<MenuItem value={'Avanos'}>Avanos</MenuItem>
									<MenuItem value={'Stryker'}>Stryker</MenuItem>
								</Select>
							</FormControl>
							<br />
							<br />
							<Button onClick={(event) => handleSubmit(event)} id="submit" color="secondary" variant="contained" disabled={!imaging}>
								Submit
							</Button>
							<Button onClick={(event) => resetForm(false)} id="resetForm" variant="contained" style={{ marginLeft: '8px' }}>
								Reset Form
							</Button>
						</>
					) : (
						<>
							<Button onClick={(event) => handleSubmit(event)} id="submit" variant="contained" color="secondary">
								Submit
							</Button>
							<Button onClick={(event) => resetForm(false)} id="resetForm" variant="contained" style={{ marginLeft: '8px' }}>
								Reset Form
							</Button>
						</>
					)}
				</>

			)
	}

	function handleNewPatientCreated(text) {
		setCreatePatientInput(text)
		setKareoApptId(null);
		setMrn('');

		const similarities = similarityTest(text, options)
		if (similarities.length > 0) {
			setDialogText("These similar patients were found. Did you mean to choose one of these?")
		} else {
			setDialogText("Are you sure you want to create a new patient?")
		}

		handleOpen()
	}

	function handleNewReferringProviderCreated(name) {
		setReferredByInput(name);

		const similarities = similarityTest(name, referringProviders);
		if (similarities.length > 0) {
			setReferringProviderDialogText("These similar providers were found. Did you mean to choose one of these?")
		} else {
			setReferringProviderDialogText("Are you sure you want to create a new referring provider?")
		};

		handleOpenReferringProviderDialog();
	}

	if (dataLoading) return (
		<div>
			<SEO title={title} />
			<h1>{title}</h1>
			<h2>Loading...</h2>
		</div>
	)

	if (procedures.error) return (
		<div>
			<SEO title={title} />
			<h1>{title}</h1>
			<h2>ERROR: {procedures.error.message}</h2>
		</div>
	)

	return (
		<>
			<SEO title={title} />
			{/* <h1>{ (props.selectedTicket && props.selectedTicket.isLocked) ? title + ' (locked) ' : title }</h1> */}
			<h1 style={{ display: 'flex', alignItems: 'center' }}>
				{isTrainingChecked && <span style={{ marginRight: '16px' }}>Training Log</span>}
				{!isTrainingChecked && <span style={{ marginRight: '16px' }}>{title}</span>}
				{' '}
				{(props.selectedTicket && props.selectedTicket.isLocked && !PermissionsProvider.hasRole(user, HPM_ROLE.Corporate)) && (
					<Tooltip title="This ticket is locked. Contact an admin to modify.">
						<LockIcon color="secondary" fontSize="small" />
					</Tooltip>
				)}
				{(props.selectedTicket && props.selectedTicket.isLocked && PermissionsProvider.hasRole(user, HPM_ROLE.Corporate)) && (
					<Tooltip title="Unlock this ticket">
						<Button onClick={handleTicketUnlockClick} variant="outlined" color="secondary">
							{!isLocking && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '8px' }}>
								<LockIcon color="secondary" fontSize="small" />
							</div>}
							{isLocking && <CircularProgress size={24} />}

						</Button>
					</Tooltip>
				)}
				{(props.selectedTicket && !props.selectedTicket.isLocked && PermissionsProvider.hasRole(user, HPM_ROLE.Corporate)) && (
					<Tooltip title="Lock this ticket">
						<Button onClick={handleTicketLockClick} variant="outlined" color="primary">
							{!isLocking && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '8px' }}>
								<LockOpenIcon color="primary" fontSize="small" />
							</div>}
							{isLocking && <CircularProgress size={24} />}
						</Button>
					</Tooltip>
				)}
			</h1>


			{!props.selectedTicket && (
				<Chip
					label={<div className={classes.patientCounter}>You have seen <strong>{appointmentsSeen.length}</strong> of your <strong>{patientsToday.length}</strong> scheduled patients today</div>}
					onClick={handleClick}
					onDelete={handleClick}
					deleteIcon={appointmentsSeen.length === patientsToday.length ? <EventAvailableIcon /> : <EventBusyIcon />}
					variant="outlined"
					color={appointmentsSeen.length === patientsToday.length ? 'primary' : 'secondary'}
					className={classes.patientCounterContainer}
				/>
			)}

			{PermissionsProvider.hasRole(user, 'Training') &&
				<>
					<br />
					<FormControlLabel
						control={
							<Switch
								checked={isTrainingChecked}
								onChange={(event) => { setIsTrainingChecked(event.target.checked); }}
								name="Is Training Switch"
								color="secondary"
							/>
						}
						label="Training?"
					/>
				</>
			}

			{PermissionsProvider.hasRole(user, 'Corporate') ? (<>
				<div className="mt-1 flex items-center">
					<div>Provider: &nbsp;</div>
					<div>
						<CreatableSelect id="provider" options={trainerOptions} aria-label="provider" value={provider} onChange={(event) => onProviderChange(event, provider)} className="w-64 max-w-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Provider" />
					</div>
				</div>
			</>)
				: null
			}
			<div className="mt-1">
				Date of Encounter: <DatePicker
					name="date"
					selected={new Date(date)}
					onChange={(date) => handleDateChange(date)}
					customInput={<CustomInput />}
				/>
			</div>

			{(!PermissionsProvider.hasRole(user, 'Training')
				|| (PermissionsProvider.hasRole(user, 'Training') && !isTrainingChecked)
			) ? (<>
				<div className="mt-1 flex items-center">
					<div>Patient: &nbsp;</div>
					<div>
						<CreatableSelect id="patient" options={options} aria-label="patient" value={{ label: patientName, key: patientName }} onChange={(event) => handlePatientChange(event)} className="w-64 max-w-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Patient Name" onCreateOption={(event) => handleNewPatientCreated(event)} />
						<Dialog onClose={handleClose} aria-labelledby="create-patient-dialog" open={isDialogOpen}>
							<DialogTitle id="create-patient-dialog">{dialogText}</DialogTitle>
							<List>
								{similarityOptions.map((similarity) => (
									<ListItem button onClick={() => handleClose(similarity)} key={similarity}>
										<ListItemText primary={similarity} />
									</ListItem>
								))}

								<ListItem autoFocus button onClick={() => handleClose(createPatientInput)}>
									<ListItemText primary="Create New Patient" />
								</ListItem>
							</List>
						</Dialog>
					</div>
				</div>
				{displayCancellation ?
					<div className="mt-1 flex items-center">
						<div> Patient cancellation or no-show?&nbsp;</div>
						<Checkbox
							checked={cancellationChecked}
							onChange={handleCancellationChecked}
							inputProps={{ 'aria-label': 'Patient cancellation or no-show?' }}
						/>
						{cancellationChecked ? (<>
							<div>
								<FormControl variant="outlined" className={classes.formControl}>
									<Select
										labelId="cancellationLabel"
										id="cancellation"
										value={cancellationSelection}
										onChange={handleCancellationSelected}
									>
										{cancellationOptions.map(option => {
											return (<MenuItem value={option.value}>{option.label}</MenuItem>);
										})}
									</Select>
								</FormControl>
							</div>
						</>) : null
						}
						{cancellationSelection === 'Other' && (<>
							<div>
								<TextField id="outlined-basic" label="Cancellation Reason" variant="outlined"
									value={cancellationInput}
									onChange={(e) => { setCancellationInput(e.target.value) }} />
							</div>
						</>)}
					</div>
					: null}

				<div className="mt-1 flex items-center">
					{/* Location: <input aria-label="location" onChange={(event) => setLocation(event.target.value)} className="m-1 shadow appearance-none border rounded max-w-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="location" type="text" placeholder="Location" value={location ? location : ''} /> */}
					<div>Location: &nbsp;</div>
					<div>
						<CreatableSelect id="location" value={{ label: selectedLocation.practiceName, key: selectedLocation.id }} options={locationOptions} aria-label="location" onChange={(event) => handleLocationSelected(event)} className="w-64 max-w-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Location" />
					</div>
				</div>
				<div className="mt-1">
					MRN: <input aria-label="mrn" onChange={(event) => setMrn(event.target.value)} className="m-1 shadow appearance-none border rounded max-w-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="mrn" type="text" placeholder="MRN" value={mrn ? mrn : ''} disabled={kareoApptId != null} />
				</div>

				<div className="mt-1 flex items-center">
					<div>Patient referred <u>for this visit</u> from provider <u>outside</u> HPM? &nbsp;</div>
					<Checkbox
						checked={referringProviderChecked}
						onChange={handleReferringProviderChecked}
						inputProps={{ 'aria-label': 'Has referring provider?' }}
					/>
					{referringProviderChecked ?
						<div>
							<CreatableSelect id="referring-provider" options={referringProviders} aria-label="referring-provider" value={{ label: referredByInput, key: referredByInput }} onChange={(event) => handleReferringProviderChange(event)} className="w-64 max-w-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Referred by..." onCreateOption={(event) => handleNewReferringProviderCreated(event)} isDisabled={!location.id} />
							<Dialog onClose={handleReferringProviderClose} aria-labelledby="create-referring-provider-dialog" open={isReferringProviderDialogOpen}>
								<DialogTitle id="create-referring-provider-dialog">{referringProviderDialogText}</DialogTitle>
								<List>
									{similarityOptions.map((similarity) => (
										<ListItem button onClick={() => handleReferringProviderSimilarityOption(similarity)} key={similarity}>
											<ListItemText primary={similarity} />
										</ListItem>
									))}

									<ListItem autoFocus button onClick={() => handleReferringProviderClose(referredByInput)}>
										<ListItemText primary="Create New Referring Provider" />
									</ListItem>
								</List>
							</Dialog>
						</div>
						: null}
				</div>

				<div className="mt-1">
					Insurance:
					<div id="insuranceGroup" className="mt-2 max-w-md">
						{insuranceOptions.map((value) => {
							let classes = (insurance.includes(value) || (value === "Other" && otherInsurance)) ? "bg-blue-500 text-white" : "bg-transparent text-blue-700"
							return (<button key={value} onClick={(event) => handleInsuranceClick(event)} className={classes + " font-semibold py-2 px-4 border border-blue-500 rounded"} name="insurance" id={value} value={value}>{value}</button>)
						})}

						{otherInsurance &&
							<TextField id="otherInsurance" label="Other Description" variant="outlined"
								style={{ marginTop: '8px' }}
								value={otherInsuranceValue ? otherInsuranceValue : ''}
								onChange={(event) => setOtherInsuranceValue(event.target.value)} />
						}

					</div>
				</div>
			</>) : null}

			{(PermissionsProvider.hasRole(user, 'Training') && isTrainingChecked) ? (<>
				<div className="mt-1 flex items-center">
					<div>Trainer: &nbsp;</div>
					<div>
						<CreatableSelect id="trainer" options={trainerOptions} aria-label="trainer" onChange={(event) => setTrainer(event.value)} className="w-64 max-w-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Trainer Name" />
					</div>
				</div>
				<div className="mt-1 flex items-center">
					<div>Student Type: &nbsp;</div>
					<div>
						<CreatableSelect id="studentType" options={studentOptions} aria-label="student type" onChange={(event) => setStudentType(event.value)} className="w-64 max-w-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Student Type" />
					</div>
				</div>

				<div className="mt-1 flex items-center">
					{/* Location:
					<input aria-label="location" onChange={(event) => setLocation(event.target.value)} className="m-1 shadow appearance-none border rounded max-w-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="location" type="text" placeholder="Location" value={location ? location : ''} /> */}
					<div>Location: &nbsp;</div>
					<div>
						<CreatableSelect id="location" value={{ label: selectedLocation.practiceName, key: selectedLocation.id }} options={locationOptions} aria-label="location" onChange={(event) => handleLocationSelected(event)} className="w-64 max-w-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Location" />
					</div>
				</div>
			</>) : null}

			<div className="mt-1 flex items-center">
				<div>Referred to other specialty? &nbsp;</div>
				<Checkbox
					checked={referredToChecked}
					onChange={handleReferredToChecked}
					inputProps={{ 'aria-label': 'primary checkbox' }}
				/>
				{referredToChecked ? (<>
					<div>
						<FormControl variant="outlined" className={classes.formControl}>
							<Select
								labelId="referredToLabel"
								id="referredTo"
								value={referredToSelection}
								onChange={handleReferredToSelected}
							>
								{referralOptions.map(option => {
									return (<MenuItem value={option.value}>{option.label}</MenuItem>);
								})}
							</Select>
						</FormControl>
					</div>
				</>) : null
				}

				{referredToSelection === 'Other' && (<>
					<div>
						<TextField id="outlined-basic" label="Other Description" variant="outlined"
							value={referredToInput}
							onChange={(e) => { setReferredToInput(e.target.value) }} />
					</div>
				</>)
				}


			</div>

			{PermissionsProvider.hasRole(user, 'Corporate')
				|| PermissionsProvider.hasRole(user, 'Procedure')
				|| (PermissionsProvider.hasRole(user, 'Training') && isTrainingChecked)
				? (<>
					<FormControlLabel
						style={{ marginBottom: '8px' }}
						control={
							<Switch
								checked={isClinicChecked}
								onChange={(event) => { setIsClinicChecked(event.target.checked); handleClinicChecked(event.target.checked); }}
								name="Is Clinic Switch"
								color="secondary"
							/>
						}
						label="Is Clinic Visit?"
					/>
				</>) : null}

			{(
				((PermissionsProvider.hasRole(user, 'Corporate') || PermissionsProvider.hasRole(user, 'Procedure'))
					|| (PermissionsProvider.hasRole(user, 'Training') && isTrainingChecked))
				&& !isClinicChecked)
				?
				(<>
					<div style={{ marginBottom: '8px' }}>Visit Start and End Times: &nbsp;</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: '8px', width: '200px' }}>
							<LocalizationProvider dateAdapter={AdapterDayjs} >
								<TimePicker
									label="Procedure Start"
									value={procedureStartTime}
									onChange={handleProcedureStartTime}
								// disabled
								/>
							</LocalizationProvider>
						</div>
						<div>&nbsp; - &nbsp;</div>
						<div style={{ width: '200px' }}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<TimePicker
									label="Procedure End"
									value={procedureEndTime}
									onChange={handleProcedureEndTime}
								/>
							</LocalizationProvider>
						</div>
					</div>
				</>)
				:
				(<>
					{selectedAppointment &&
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Tooltip title={`(${selectedAppointment.date} UTC)`} placement="right">
								<div>
									{/* Appointment Time: {`${new Date(Date.parse(`${selectedAppointment.localDate}`.replace('Z', '-08:00'))).toLocaleString('en-US', {timeZone: 'America/Los_Angeles'})}`} (Pacific Time) */}
									Appointment Time: {`${new Date(`${selectedAppointment.date}`).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })}`} (Pacific Time)
									{/* ({`${selectedAppointment.localDate}`.replace('Z', '-08:00')}) */}
								</div>
							</Tooltip>
						</div>
					}
					{/* <div style={{ marginBottom: '16px' }}>
						Visit Duration: &nbsp;<CreatableSelect id="visit-duration"
							value={clinicVisitTime} options={clinicMinuteOptions} aria-label="visit-duration"
							onChange={(event) => handleClinicVisitTime(event)} className="w-64 max-w-xs text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Time" />
					</div> */}

					<Box sx={{ minWidth: 180, display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: '8px' }}>Visit Duration:</div>
						<FormControl variant="outlined" size="small" sx={{ minWidth: 180 }}>
							<Select
								value={clinicVisitTime}
								onChange={(event) => handleClinicVisitTime(event)}
							>
								{clinicMinuteOptions.map(option => {
									return (<MenuItem value={option.value}>{option.label}</MenuItem>)
								})}
							</Select>
						</FormControl>
					</Box>

				</>)
			}

			{(PermissionsProvider.hasRole(user, 'Corporate') || (PermissionsProvider.hasRole(user, 'Procedure') && !isClinicChecked)) ?
				(<div className="mt-1 flex items-center">
					<div>Was procedure aborted? &nbsp;</div>
					<Checkbox
						checked={procedureAbortedChecked}
						onChange={handleProcedureAbortedChecked}
						inputProps={{ 'aria-label': 'primary checkbox' }}
					/>
					{procedureAbortedChecked ? (<>
						<div>
							<FormControl variant="outlined" className={classes.formControl}>
								<Select
									labelId="procedureAbortedlabel"
									id="procedureAborted"
									value={procedureAbortedSelection}
									onChange={handleProcedureAbortedSelected}
								>
									{procedureAbortedOptions.map(option => {
										return (<MenuItem value={option.value}>{option.label}</MenuItem>);
									})}
								</Select>
							</FormControl>
						</div>
					</>) : null
					}
					{procedureAbortedSelection === 'Other' && (
						<>
							<div>
								<TextField id="procedureAbortedOther" label="Other Description" variant="outlined"
									value={procedureAbortedInput}
									onChange={(e) => { setProcedureAbortedInput(e.target.value) }} />
							</div>
						</>)
					}
				</div>) : null}

			{((PermissionsProvider.hasRole(user, 'Corporate')
				|| PermissionsProvider.hasRole(user, 'Procedure')
				|| (PermissionsProvider.hasRole(user, 'Training') && isTrainingChecked))
				&& !isClinicChecked)
				?
				(<div className="mt-1 mb-4">
					<div>Description of procedure:</div>
					<textarea aria-label="description" onChange={(event) => setDescription(event.target.value)} className="w-full max-w-md shadow appearance-none border rounded max-w-sm py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description" type="text" placeholder="Description..." value={description ? description : ''} />
				</div>)
				:
				(<><div className="mt-1">
					<input
						name="blindInj"
						type="checkbox"
						checked={blindInj}
						onChange={(e) => setBlindInj(e.target.checked)} />
					&nbsp; Blind Injection Performed
				</div>
					<div className="mt-1">
						<input
							name="ptOrdered"
							type="checkbox"
							checked={ptOrdered}
							onChange={(e) => setPtOrdered(e.target.checked)} />
						&nbsp; Physical Therapy Ordered
					</div>
					<div className="mt-1">
						<input
							name="labOrdered"
							type="checkbox"
							checked={labOrdered}
							onChange={(e) => setLabOrdered(e.target.checked)} />
						&nbsp; Labs Ordered
					</div>
					<div className="mt-1">
						<input
							name="pxOrdered"
							type="checkbox"
							checked={pxOrdered}
							onChange={(e) => setPxOrdered(e.target.checked)} />
						&nbsp; Procedure Ordered
					</div>
					<div className="mt-1 mb-4">
						<input
							name="imagingOrdered"
							type="checkbox"
							checked={imagingOrdered}
							onChange={(e) => setImagingOrdered(e.target.checked)} />
						&nbsp; Imaging Ordered
					</div>

					<IcdSelector value={selectedIcdCodes} onChange={handleIcdChange}></IcdSelector>
					
				</>)}
			{/* <CptSearch /> */}

			{activeTier && activeTier.tierId === 6 && isClinicChecked &&
				<FormControlLabel
					control={
						<Switch
							checked={hasClinicProceduresChecked}
							onChange={(event) => { handleClinicProcedureChecked(event.target.checked); }}
							name="Is Clinic Switch"
							color="secondary"
						/>
					}
					label="Clinic Procedure Performed?"
				/>
			}		

			<CptSearch
				procedures={procedures}
				completedProcedures={completedProcedures}
				setCompletedProcedures={setCompletedProcedures}
				location={location}
				imagingCpts={imagingCpts}
				setImaging={setImaging}
				setImagingCptSelected={setImagingCptSelected}
				getMatchingNotifications={getMatchingNotifications}
				warningNotifications={warningNotifications}
				setWarningNotifications={setWarningNotifications}
				mbbCount={mbbCount}
				setmbbCount={setmbbCount}
				mbbCptSelected={mbbCptSelected}
				setmbbCptSelected={setmbbCptSelected}
				isClinicChecked={isClinicChecked}
				isTrainingChecked={isTrainingChecked}
				handleClose={handleClose}
			/>

			{hasClinicProceduresChecked && activeTier && activeTier.tierId === 6 &&
				// <div>TIER 6</div>
				<ClinicProceduresCptSearch
					cpts={procedures.data.cpts.cpts}
					selected={selectedClinicProcedures}
					onChange={(cpts) => handleClinicProcedureCpts(cpts)}
				/>
			}



			<EditButtons />

			<Dialog open={requestDialogOpen}>
				{/* <form> */}
				<DialogTitle>Submit Change Request</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please describe details about your changes and why they are needed.
					</DialogContentText>

					<TextField
						autoFocus
						id="requestDetails"
						name="requestDetails"
						required
						variant="outlined"
						margin="dense"
						label="Details"
						fullWidth
						multiline
						minRows={3}
						onChange={(e) => handleRequestInputChange(e)}
						value={requestDetails}
					/>

				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelRequest}>
						Cancel
					</Button>
					<Button disabled={submitRequestBtnDisabled} onClick={(event) => handleSubmitRequest(event)} color="primary">
						Submit Request
					</Button>
				</DialogActions>
				{/* </form> */}
			</Dialog>
		</>
	)
}

export default ChargeSheet
